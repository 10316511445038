"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { hideMainLoadingScreen } from "@/components/NavigationLink";

export default function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    hideMainLoadingScreen();
    // You can now use the current URL
    // ...
  }, [pathname, searchParams]);

  return null;
}
