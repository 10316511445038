"use client";

import { Fragment, useEffect, useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Link from "@/components/NavigationLink";
import Logo from "@/components/logo";
import CallIcon from "@mui/icons-material/Call";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { usePathname } from "next/navigation";
import _ from "lodash";
import DynamicIcon from "@/components/DynamicIcon";
import removeNonNumeric from "@/utils/removeNonNumeric";

function useGeneratePageClasses() {
  const path = usePathname();
  let pathArray = path.split("/");
  pathArray = _.compact(pathArray);
  let classes = "";
  const isHome = pathArray.length <= 0;
  if (!isHome) {
    classes = "inner";
  } else {
    classes = "home";
  }
  return classes;
}

const navItems = [
  {
    label: "Rent",
    url: "/rent",
    children: [
      {
        label: "Residential",
        url: "/rent/residential",
        children: [
          {
            label: "Apartment/Flat",
            url: "/rent/residential/apartment",
          },
          {
            label: "Villa/House",
            url: "/rent/residential/villa",
          },
          {
            label: "Townhouse",
            url: "/rent/residential/townhouse",
          },
          {
            label: "Penthouse",
            url: "/rent/residential/penthouse",
          },
          {
            label: "Building",
            url: "/rent/residential/building",
          },
          {
            label: "Villa Compound",
            url: "/rent/residential/villa-compound",
          },
          {
            label: "Residential Floor",
            url: "/rent/residential/floor",
          },
        ],
      },
    ],
  },
  {
    label: "Buy",
    url: "/buy",
  },
  {
    label: "Off-Plan",
    url: "/off-plan",
  },
  {
    label: "Investment Packages",
    url: "/investment-packages",
  },

  {
    label: "Services",
    url: "/services",
  },
  {
    label: "Insights",
    url: "/insights",
  },
  {
    label: "About",
    url: "/about",
  },
];

const useOpacity = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const newOpacity = Math.min(scrollPosition * 0.03, 1); // Adjust the factor as needed
      setOpacity(newOpacity);
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    opacity,
  };
};

const Navbar = ({ data }) => {
  const { phone, email, mobile, facebook, instagram, linkedin, twitter } = data;

  let whatsapp = null;
  if (mobile) {
    whatsapp = removeNonNumeric(mobile);
  }

  const contact = [
    {
      label: phone,
      icon: "RiPhoneLine",
      url: "tel:" + removeNonNumeric(phone),
    },
    {
      label: mobile,
      icon: "RiWhatsappLine",
      url: `https://wa.me/${removeNonNumeric(whatsapp)}`,
    },
    {
      label: email,
      icon: "RiMailLine",
      url: "mailto:" + email,
    },
  ];
  // social
  let social = [
    {
      icon: "icon-linkedin-logo",
      url: linkedin?.trim(),
    },
    {
      icon: "icon-facebook",
      url: facebook?.trim(),
    },
    {
      icon: "icon-x",
      url: twitter?.trim(),
    },
    {
      icon: "icon-instagram",
      url: instagram?.trim(),
    },
  ];
  social = social.filter((item) => item.url && item.url?.trim() !== "");
  const { opacity } = useOpacity();
  const [mounted, setMounted] = useState(false);

  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    if (newOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
    setOpen(newOpen);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const DrawerList = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      className="w-[80vw] mt-[3.4rem] drawer-list max-w-[320px]"
    >
      <ul>
        {navItems.map((item, index) => {
          const { label, url, children } = item;
          return (
            <Fragment key={index}>
              <Divider />
              <li>
                <Link
                  href={url}
                  className="btn btn-white block w-full rounded-none"
                  showActiveStyle
                >
                  {label}
                </Link>
              </li>
            </Fragment>
          );
        })}
        <Divider />
        <li className="btn-box">
          <Link
            className="btn nav-item inline-block"
            href="/contact"
            showActiveStyle
          >
            List Your Property
          </Link>
        </li>
        <Divider />
        <li className="btn-box-x py-6">
          <ul className="flex flex-col gap-2 text-sm text-muted">
            {contact.map((item, index) => (
              <li key={index}>
                <a
                  href={item.url}
                  target="_blank"
                  className="flex gap-2 items-center"
                >
                  <DynamicIcon icon={item.icon} className="text-primary" />
                  <span className="label text-body">{item.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );

  const pageClasses = useGeneratePageClasses();

  const headerClassName =
    !mounted && pageClasses === "home"
      ? "absolute"
      : pageClasses === "home"
      ? "fixed"
      : "sticky";

  return (
    <header className={`${headerClassName} top-0 inset-x-0 z-50`}>
      <nav
        style={{ "--tw-bg-opacity": pageClasses === "inner" ? 1 : opacity }}
        className={`${
          opacity > 0 ? "inner" : pageClasses
        } top-0 inset-x-0 p-4 lg:p-6 gap-4 flex justify-between text-white ${
          open ? "open" : ""
        }`}
      >
        <Logo className="z-30" />
        <div id="menu-wrapper" className="flex items-center shrink-0">
          <div className="nav-items gap-3 xl:gap-6 items-center hidden lg:flex">
            {navItems.map((item, index) => (
              <Link
                className="nav-item flex gap-1 items-center"
                key={index}
                href={item.url}
                showActiveStyle
              >
                {item.icon && item.icon()}
                <span className="label">{item.label}</span>
              </Link>
            ))}
            {/* <div className="w-px h-3 bg-white/90"></div> */}

            <a
              className="nav-item flex gap-1 items-center relative"
              href={`https://wa.me/${removeNonNumeric(whatsapp)}`}
              target="_blank"
            >
              <CallIcon style={{ width: 16, height: 16 }} />
              <span className="label">{mobile}</span>
            </a>

            <Link
              className="btn nav-item !px-3 !py-2"
              href="/list-property"
              showActiveStyle
            >
              List Your Property
            </Link>
          </div>

          <button
            onClick={toggleDrawer(!open)}
            className="toggle block relative z-20 lg:hidden"
          >
            <span className="sr-only">{open ? "Close Menu" : "Open Menu"}</span>
            {open ? (
              <CloseOutlinedIcon className="menu-toggle text-3xl" />
            ) : (
              <MenuOutlinedIcon
                className=" text-3xl menu-toggle"
                style={{ width: 30, height: 30 }}
              />
            )}
          </button>

          <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            disableScrollLock={true}
            className="drawer"
          >
            {DrawerList}
          </Drawer>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
