import React from "react";
import Link from "@/components/NavigationLink";

const Logo = (props) => {
  const { className } = props;
  return (
    <Link href="/" className={`logo ${className}`}>
      <span className="sr-only">logo</span>

      <svg
        viewBox="0 0 310 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full"
      >
        <path
          d="M68.46 12.236v14.69h-2.19v-14.69h2.19Zm26.52 7.26c0 3.12-1.54 5.66-4.01 6.97v.05c.48.16.85.39 1.12.62l3.72 3.21h-3.07l-3.67-3.17c-.67.14-1.38.23-2.11.23-4.52 0-8.03-3.14-8.03-7.91 0-4.38 3.53-7.73 8.03-7.73s8.03 3.35 8.03 7.73h-.01Zm-13.72 0c0 3.76 2.48 5.89 5.69 5.89 3.21 0 5.69-2.13 5.69-5.89 0-3.42-2.45-5.71-5.69-5.71s-5.69 2.29-5.69 5.71Zm29.06-7.45c4.36 0 6.28 2.11 6.28 4.95 0 3.17-1.97 4.95-5.99 4.95h-2.96v5.18h-2.25v-15.09h4.91l.01.01Zm.25 8.03c2.66 0 3.74-1.08 3.74-3.07 0-1.4-.5-2.94-3.99-2.94h-2.66v6.01h2.91Zm16.47-8.03h5.14c3.97 0 5.69 1.97 5.69 4.61 0 1.61-.73 3.26-2.61 4.04v.09l4.2 6.35h-2.59l-3.97-5.99h-3.6v5.99h-2.25v-15.09h-.01Zm4.91 7.22c2.55 0 3.62-.78 3.62-2.61 0-1.54-.76-2.59-3.37-2.59h-2.91v5.21h2.66v-.01Zm32.04.23c0 4.54-3.28 7.91-8.03 7.91s-8.03-3.14-8.03-7.91c0-4.38 3.53-7.73 8.03-7.73s8.03 3.35 8.03 7.73Zm-13.72 0c0 3.76 2.48 5.89 5.69 5.89 3.21 0 5.69-2.13 5.69-5.89 0-3.42-2.45-5.71-5.69-5.71s-5.69 2.29-5.69 5.71Z"
          fill="#fff"
          className="dark"
        />
        <path
          d="M30.72 9.016c-.42-.37-1.06-.34-1.44.08-.37.42-.34 1.06.08 1.44l1.15 1.03c.19.17.44.26.68.26.28 0 .56-.12.76-.34.37-.42.34-1.06-.08-1.44l-1.15-1.03Zm9.48.08-1.03 1.15c-.37.42-.34 1.06.08 1.44.19.17.44.26.68.26.28 0 .56-.12.76-.34l1.03-1.15c.37-.42.34-1.06-.08-1.44-.42-.37-1.06-.34-1.44.08Zm7.82-8.8c-.85 1.07-1.85 1.93-2.9 2.5-.58.31-1.2.53-1.86.72a10.367 10.367 0 0 0-4.39-2.94c-4.09-1.42-8.48-.13-11.2 2.92-.63-.18-1.23-.4-1.78-.7-1.05-.57-2.05-1.43-2.9-2.5l-1.59 1.27c1.02 1.28 2.24 2.33 3.53 3.03.49.27.99.47 1.5.65-.32.56-.59 1.15-.81 1.78-.07.19-.12.39-.18.58-6.3 5.51-12.56 14.33-16.35 20.8l-1.03 1.76 2.02-.23c5.64-.64 10.29-2.54 13.2-4.02 3.17-1.61 4.99-3.06 5.06-3.12l-1.27-1.59c-.06.05-6.07 4.8-15.13 6.4 3.36-5.45 8.16-12.07 13.11-16.87.05 1.61.46 3.2 1.24 4.64a10.414 10.414 0 0 0 10.57 5.39c-.72.57-1.52 1.16-2.4 1.76-2.96 2.02-6.5 3.97-10.25 5.66-4.13 1.86-8.36 3.32-12.24 4.2-4.42 1.01-8.36 1.31-11.71.88L0 35.286c1.12.14 2.25.21 3.4.21 7.46 0 15.31-2.74 20.27-4.85v5.58c0 .55.43 1 .98 1.02 1.82.07 3.21.05 4.23.03.99-.01 2.12-.03 2.31.11l.02.02c.19.32.53.49.87.49a1.021 1.021 0 0 0 .87-1.55c-.71-1.16-1.91-1.15-4.09-1.11-.85.01-1.86.03-3.15 0v-5.5c7.47-3.45 13.84-7.81 16.08-11.02 1.63-1.24 2.85-2.92 3.53-4.87.91-2.63.75-5.46-.47-7.96-.1-.21-.21-.42-.32-.62.54-.18 1.07-.4 1.59-.68 1.29-.7 2.51-1.75 3.53-3.03L48.06.286l-.04.01Zm-9.82 2.21c1.03.36 1.97.91 2.77 1.61-.99.27-1.99.59-2.92 1.15-1.17.69-2.07 1.73-2.54 2.92a6.04 6.04 0 0 0-2.54-2.92c-.96-.57-1.98-.9-3-1.17a8.416 8.416 0 0 1 8.24-1.59h-.01Zm5.17 10.67c-.56 1.61-1.58 3-2.95 4.01a8.394 8.394 0 0 1-7.72 1.17 8.363 8.363 0 0 1-4.64-3.95 8.344 8.344 0 0 1-.75-6c.06-.25.13-.49.21-.73.23-.67.55-1.3.92-1.87.04 0 .07.02.11.03 1.22.3 2.37.59 3.36 1.18.82.49 1.43 1.2 1.73 2.02.17.46.25.98.33 1.54.12.76.24 1.55.6 2.34a1.012 1.012 0 0 0 1.84 0c.36-.79.48-1.57.6-2.34.08-.55.16-1.07.33-1.54.3-.82.91-1.54 1.73-2.02.99-.59 2.14-.87 3.36-1.18h.02c.2.3.37.61.53.94.98 2.02 1.11 4.29.38 6.41l.01-.01Z"
          fill="url(#a)"
        />
        <path
          d="M180.4 15.566h2.41c1.86 0 2.67.93 2.67 2.17 0 .75-.34 1.53-1.23 1.9v.04l1.97 2.99H185l-1.86-2.81h-1.69v2.81h-1.06v-7.09l.01-.01Zm2.31 3.39c1.2 0 1.7-.37 1.7-1.23 0-.72-.36-1.22-1.58-1.22h-1.37v2.45h1.25Zm11.85.5v2.25h4.28v.95h-5.33v-7.09h5.12v.95h-4.06v2.06h3.63v.88h-3.64Zm14.79-3.89 3.17 7.09h-1.11l-.85-1.9h-3.2l-.84 1.9h-1.11l3.17-7.09h.78-.01Zm-1.65 4.42h2.51l-.53-1.19c-.2-.46-.7-1.92-.7-1.92h-.04s-.5 1.44-.7 1.92l-.54 1.19Zm13.15-4.42v6.14h4.01v.95h-5.07v-7.09h1.06Zm21.07 3.89v2.25h4.28v.95h-5.33v-7.09h5.12v.95h-4.06v2.06h3.63v.88h-3.64Zm11.22 2.71.36-.91c.56.27 1.45.58 2.34.58 1.23 0 1.81-.56 1.81-1.27 0-.32-.16-.59-.43-.75-.28-.17-.8-.27-1.45-.34-.75-.09-1.3-.2-1.75-.44-.67-.36-.92-.93-.92-1.54 0-1.15 1.1-2.05 2.71-2.05 1 0 1.79.2 2.45.52l-.34.88c-.51-.22-1.37-.45-2.1-.45-1.24 0-1.65.57-1.65 1.1 0 .27.11.53.45.73.28.16.71.22 1.37.3.69.1 1.35.23 1.74.44.65.36.95.88.95 1.61 0 1.25-1.06 2.22-2.81 2.22-1.14 0-1.97-.23-2.71-.63h-.02Zm15.01-5.65h-2.81v-.95h6.68v.95h-2.81v6.14h-1.06v-6.14Zm13.42-.95 3.17 7.09h-1.11l-.85-1.9h-3.2l-.84 1.9h-1.11l3.17-7.09h.78-.01Zm-1.65 4.42h2.51l-.53-1.19c-.2-.46-.7-1.92-.7-1.92h-.04s-.5 1.44-.7 1.92l-.54 1.19Zm13.23-3.47h-2.81v-.95h6.68v.95h-2.81v6.14h-1.06v-6.14Zm12.2 2.94v2.25h4.28v.95h-5.33v-7.09h5.12v.95h-4.06v2.06h3.63v.88h-3.64Z"
          fill="#fff"
          className="dark"
        />
        <defs>
          <linearGradient
            id="a"
            x1="-6.328"
            y1="0"
            x2="194.512"
            y2="172.04"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CE4A73" />
            <stop offset=".47" stopColor="#D31872" />
            <stop offset=".79" stopColor="#DA4D6D" />
            <stop offset="1" stopColor="#CB3F60" />
          </linearGradient>
        </defs>
      </svg>
    </Link>
  );
};

export default Logo;
