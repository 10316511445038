"use client";
import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Button from "@/components/Button";
import ThemeUIProvider from "@/components/themeUIProvider";

const SubscribeForm = () => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const [values, setValues] = useState(null);

  async function onSubmit(event) {
    event.preventDefault();
    const { default: axios } = await import("axios");
    const yup = await import("yup");

    const schema = yup.object().shape({
      email: yup.string().email("Invalid email").required("Required field"),
    });

    setIsLoading(true);
    setErrors(null);
    try {
      const isValid = await schema.validate(values, { abortEarly: false });
      if (isValid) {
        setErrors(null);
        await axios.post("/api/subscribe", values);
        setSuccess(true);
        setValues(null);
        setIsLoading(false);
        formRef.current.reset();

        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
    } catch (error) {
      const formErrors = {};
      error?.inner.forEach((err) => {
        formErrors[err.path] = err.errors.join(", ");
      });
      setErrors(formErrors);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFieldChange = (field) => {
    setValues({ ...values, ...field });
  };

  return (
    <ThemeUIProvider>
      <form onSubmit={onSubmit} ref={formRef}>
        <div className="flex flex-col gap-3 items-start">
          <TextField
            variant="outlined"
            size="small"
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            id="email"
            label="Email"
            name="email"
            fullWidth
            autoComplete="off"
            sx={{
              color: "red",
              "& input": {
                color: "#fff",
              },
              "& label": {
                color: "#fff !important",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#FFF !important",
                  borderWidth: "1px !important",
                },
              },
            }}
            onChange={(e) => {
              handleFieldChange({ email: e.target.value });
            }}
            error={errors?.email ? true : null}
            helperText={errors?.email}
          />
          <Button
            variant="contained"
            color="primary"
            className="bg-primary text-white font-normal text-sm !py-2"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Submitting" : "Subscribe"}
          </Button>
        </div>
      </form>
      {success && (
        <div className="text-green-600 font-bold mt-4">
          Thank you for subscribing.
        </div>
      )}
    </ThemeUIProvider>
  );
};

export default SubscribeForm;
