"use client";

import { useEffect, useState } from "react";

export default function CookieConsent() {
  const [hidden, setHidden] = useState(true);
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (!hidden) return;

    const onScroll = async () => {
      setHidden(false);
      const { CookieConsent } = await import("react-cookie-consent");

      setComponent(
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="CookieConsent"
          containerClasses="!bg-white !text-black border-t"
          buttonClasses="btn !bg-primary !text-white !rounded"
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      );
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [hidden]);

  if (hidden) return <></>;

  return Component;
}
